import { createSlice } from '@reduxjs/toolkit'

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    stateSidebarShow: true,
  },
  reducers: {
    toggleSidebar: (state, action) => {
      return { ...state, stateSidebarShow: action.payload }
    },
  },
})

export const { toggleSidebar } = uiSlice.actions
export const selectStateSidebar = (state) => state.ui.stateSidebarShow
export default uiSlice.reducer
