import React, { Component, Suspense } from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import './scss/style.scss'
import Login from './views/pages/login/Login'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
class App extends Component {
  render() {
    return (
      <>
        <Toaster></Toaster>
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/" name="Login de Usuarios" element={<Login />} />
              <Route exact path="/login" name="Login de Usuarios" element={<Login />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </>
    )
  }
}

export default App
