import { useDomain } from '../cumstomHooks/useDomain'

export const Auth = () => {
  const { postAxios } = useDomain()
  const getLogin = async (username, password) => {
    const responseLogin = {
      error: false,
      messageError: '',
      data: {},
    }
    await postAxios('users/login', { username, password })
      .then((resolve) => {
        responseLogin.data = resolve
      })
      .catch((error) => {
        responseLogin.error = true
        if (error?.response?.status === 500) {
          responseLogin.messageError = 'El usuario y/o contraseña no son validos'
        } else if (error?.response?.data.statusCode === 401) {
          responseLogin.messageError = 'El usuario y/o contraseña no son validos'
        } else if (error?.response === undefined) {
          responseLogin.messageError =
            'Ha ocurrido un error en el proceso de login. Intente nuevamente'
        }
      })
    return responseLogin
  }
  return {
    getLogin,
  }
}
