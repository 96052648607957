import React from 'react'

export const useLocalStorage = () => {
  const getData = (keyData) => JSON.parse(localStorage.getItem(keyData))
  const setData = (keyData, data) => localStorage.setItem(keyData, JSON.stringify(data))
  const removeData = (keyData) => localStorage.removeItem(keyData)

  return {
    getData,
    setData,
    removeData,
  }
}
