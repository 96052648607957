/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import logo from './../../../assets/images/logo/logo.png'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'src/domain/Auth'
import { useLocalStorage } from 'src/cumstomHooks/useLocalStorage'
import { toast } from 'react-hot-toast'

const Login = () => {
  const { setData, getData } = useLocalStorage();
  const [user, setUser] = useState(getData('_token'));
  const navigate = useNavigate()
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [loadingButtonSubmit, setLoadingButtonSubmit] = useState(false)
  const { getLogin } = Auth()
  useEffect(() => {
    if (user !== null) {
      navigate("/dashboard", { replace: true })
    }
  }, [loadingButtonSubmit, navigate, user]);

  const handleSubmit = (event) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity()) {
      handleLoginWhitUserAndPassword()
    }
  }
  const handleLoginWhitUserAndPassword = async () => {
    setLoadingButtonSubmit(true)
    const retorno = await getLogin(username, password)
    if (!retorno.error) {
      setData('_token', retorno.data)
      setUser(retorno.data);
      navigate("/dashboard", { replace: true })
    } else {
      toast.error(retorno.messageError)
    }
    setLoadingButtonSubmit(false)
  }
  const handleChangeUsername = (e) => setUsername(e.target.value)
  const handleChangePassword = (e) => setPassword(e.target.value)

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm
                    onSubmit={handleSubmit}
                  >
                    <h1>Acceso para administradores</h1>
                    <p className="text-medium-emphasis">Ingresa con tu cuenta @partnerh.com</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        onChange={handleChangeUsername}
                        placeholder="correo@partnerh.cl"
                        required
                        value={username}
                        autoComplete="username" />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        onChange={handleChangePassword}
                        placeholder="**********"
                        required
                        value={password}
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CLoadingButton
                          color="primary"
                          variant="outline"
                          disabled={loadingButtonSubmit}
                          loading={loadingButtonSubmit}
                          className="px-4"
                          type="submit"
                        >Login</CLoadingButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-custom-partner py-5" >
                <CCardBody className="text-center">
                  <div>
                    <CImage fluid src={logo} />
                    <p className='my-5'>
                      Bienvenido al portal de administración de PartnerH
                    </p>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
