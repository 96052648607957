/* eslint-disable prettier/prettier */
import axios from 'axios'
import React from 'react'
import { useLocalStorage } from './useLocalStorage'
import { useNavigate } from 'react-router-dom'

export const useDomain = () => {
  const [loading, setLoading] = React.useState(false)
  const { getData } = useLocalStorage()
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASEURL || "http://localhost:3006/api/v1/";
  let headersConfig = {
    'x-api-key': process.env.REACT_APP_API_KEY,
  }
  if (getData('_token')) {
    headersConfig['Authorization'] = 'Bearer ' + getData('_token').data?.token
  }
  const instance = axios.create({
    timeout: 10000,
    headers: headersConfig,
  })
  const postAxios = async (collection, data, hasFile = false) => {
    setLoading(true);
    if (hasFile) {
      instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    }
    return await instance.post(baseUrl + collection, data)
  }
  const putAxios = async (collection, data) => {
    setLoading(true);
    const response = await instance.put(baseUrl + collection, data)
    return response.data;
  }
  const getAxios = async (collection, data) => {
    setLoading(true);
    const response = await instance.get(baseUrl + collection, {
      params: data
    })
    if (response?.status && response?.status === 401) {
      navigate('/logout');
    }
    return response.data;
  }

  const deleteAxios = async (collection) => {
    setLoading(true);
    try {
      return await instance.delete(baseUrl + collection)
    } catch (ex) {
      throw new Error(ex)
    }
  }
  return {
    postAxios,
    putAxios,
    deleteAxios,
    loading,
    getAxios,
  }
}
